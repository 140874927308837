import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import Notifications from '@kyvg/vue3-notification'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import i18n from './i18n'
import { createVueWait } from 'vue-wait'
import Vue3Storage from "vue3-storage";
import { createDeviceDetector } from "next-vue-device-detector";
import v3ImgPreview from 'v3-img-preview'
 
import VueLazyload from 'vue-lazyload';


const VueWait = createVueWait()
export const device = createDeviceDetector()

 



createApp(App).use(router).use(Vue3Storage, {}).use(VueWait).use(i18n).use(store).use(Notifications).use(device).use(v3ImgPreview, {
    keyboard: false,
    escClose: true,
    showToolbar: false,
})
.use(VueLazyload, {
    loading: require('./assets/img/wait.gif'),  // Optional: Add a loading image (use your own image or URL)
    error: require('./assets/img/euro.png'),      // Optional: Add an error image (use your own image or URL)
})
.mount('#app');


 
