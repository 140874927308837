<template>
  <notifications />
  <router-view />
</template>

 


<style lang="scss">
@import '@/assets/sass/style.scss';
.vue-notification-group {

  z-index: 99995000 !important;
}
</style>
