<template>
  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <CommandeTypeModal :show="(!orderType || changeType) && subscriptions.length" @close="changeType = false">
    </CommandeTypeModal>
    <NotificationMessage :show="showNoti" :restaurantMessage="filtredRestaurantMessage" @close="showNoti = false">
    </NotificationMessage>

  </Teleport>
  <header class="header_in clearfix">
    <div class="container">
      <ul id="top_left">

        <li v-on:click="changeType = true">
          <div class="styled-select currency-selector" v-if="orderType">
            <div class="selected-type"> {{ $t('order.' + orderType) }}</div>
          </div>
        </li>
      </ul>

      <div id="logo">
        <a>
          <img src="../assets/logo.png" width="140" height="45" alt="" />
        </a>
      </div>

      <div class="layer"></div>
      <ul id="top_menu">
        <li>
          <img src="../assets/img/flags/france.png" @click="setLang('fr')" />
        </li>
        <li>
          <img src="../assets/img/flags/united-kingdom.png" @click="setLang('en')" />
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CommandeTypeModal from "./commandeTypeModal.vue";
import NotificationMessage from "./NotificationMessage.vue";
import router from "../router";
export default {
  name: "app-navebar",
  props: {},
  computed: {
    ...mapGetters(["idRestaurant"]),
    ...mapGetters(["idTable"]),
    ...mapGetters(["orderTable"]),
    ...mapGetters(["orderType"]),
    ...mapGetters(["canEatIn"]),
    ...mapGetters(["canTakeOut"]),
    ...mapGetters(["canDelivery"]),
    ...mapGetters(['subscriptions']),
    ...mapGetters(['subscriptions']),
    ...mapGetters(["slugRestaurant"]),
    ...mapGetters(["restaurantMessage"]),

  },
  watch: {

    subscriptions: function () {
      if (this.canTakeOut === null || this.canDelivery === null) return;

      if (this.canEatIn && !this.canTakeOut && !this.canDelivery) {
        this.setOrderType("eatin");
      }
      if (this.canTakeOut && !this.canEatIn && !this.canDelivery) {
        this.setOrderType("takeaway");
      }
      if (!this.canTakeOut && !this.canEatIn && this.canDelivery) {
        this.setOrderType("delivery");
      }
    },
    canTakeOut: function () {
      if (this.canTakeOut === null || this.canDelivery === null) return;

      if (this.canEatIn && !this.canTakeOut && !this.canDelivery) {
        this.setOrderType("eatin");
      }
      if (this.canTakeOut && !this.canEatIn && !this.canDelivery) {
        this.setOrderType("takeaway");
      }
      if (!this.canTakeOut && !this.canEatIn && this.canDelivery) {
        this.setOrderType("delivery");
      }
    },
    canDelivery: function () {
      if (this.canTakeOut === null || this.canDelivery === null) return;

      if (this.canEatIn && !this.canTakeOut && !this.canDelivery) {
        this.setOrderType("eatin");
      }
      if (this.canTakeOut && !this.canEatIn && !this.canDelivery) {
        this.setOrderType("takeaway");
      }
      if (!this.canTakeOut && !this.canEatIn && this.canDelivery) {
        this.setOrderType("delivery");
      }
    },
    orderType: function (newOrderType, oldOrderType) {
      // Handle the change in orderType here

      // You can perform additional actions or call methods based on the new orderType value
      this.filtredRestaurantMessage = this.restaurantMessage.filter((message) => {
        if (newOrderType === "eatin" && message.delivery_types?.includes("Sur place")) {
          this.showNoti = true;
          return true;
        } else if (newOrderType === "takeaway" && message.delivery_types?.includes("Click&Collect")) {
          this.showNoti = true;
          return true;
        } else if (newOrderType === "delivery" && message.delivery_types?.includes("Livraison")) {
          this.showNoti = true;
          return true;
        }
        return false;
      });

    }
  }
  ,
  data() {
    return {
      changeType: false,
      showNoti: false,
      filtredRestaurantMessage: [],
    };
  },
  methods: {
    setOrderType(type) {
      this.$store.dispatch("updateOrderType", type);
      this.$store.dispatch('clearCurrentPage');
      if (type === "eatin") {
      
        this.$store.dispatch(
          "getProductsList",
          this.slugRestaurant
        );
      } else {
        
        this.$store.dispatch(
          "getProductsCACList",
          this.slugRestaurant
        );
      }
    },
    setLang(lng) {
      this.$i18n.locale = lng;
      let msg = "";
      if (lng == "en") {
        msg = "You current language is English";
        // Update product.name and product.description to their English versions
        if (this.selectedProduct) {
          this.selectedProduct.name = this.selectedProduct?.name_en;
          this.selectedProduct.description = this.selectedProduct?.description_en;
        }
      } else {
        msg = "Votre langue actuelle est français";
        // Update product.name and product.description to their French versions
        if (this.selectedProduct) {
          this.selectedProduct.name = this.selectedProduct?.name_fr;
          this.selectedProduct.description = this.selectedProduct?.description_fr;
        }
      }
      this.$notify({
        type: "success",
        text: msg,
        duration: 1000,
      });
    },
  },
  components: {

    CommandeTypeModal,
    NotificationMessage
  },
  created() {
    if (router.currentRoute.value.params.idRestaurant !== this.idRestaurant || router.currentRoute.value.params.idTable !== this.idTable) {
      this.$store.dispatch("removeAllCartItems");
    }

  }

};
</script>
<style scoped>
img {
  cursor: pointer;
}

ul {
  list-style-type: none;
}

#top_left {
  position: absolute;
  left: -27px;
  float: none;
  margin: 2px 0px 0px 4px;
}

.selected-type {
  margin: 0px 0px 0px 7px;
}
</style>
