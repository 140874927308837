import request from '../../../api/httpClient';
export default {
  state: {
    productItems: [],
     
    pagination: [],
    loading: false,
    currentPage: 1,
    lastPage: null, // Will be set after first fetch
    isBottom: false, // Track if user scrolled to bottom
    suggestedProducts: [],// list of suggested products
    suggestionProducts: [] // list of suggestion products

  },
  mutations: {
    
    
    UPDATE_SUGGESTED_PRODUCTS(state: any, payload: any) {
      state.suggestedProducts.push(...payload);
      
    },
    UPDATE_EXISTING_SUGGESTION_PRODUCTS(state: any, payload: any) { 

      state.suggestionProducts = payload;
       
     
    },
    UPDATE_PRODUCTS_LIST(state: any, payload: any) {
      state.productItems = payload;
    },
    SET_PRODUCTS(state: any, productItems: any) {
     
      if (state.currentPage === 1) {
        state.productItems = productItems;
      } else {
        state.productItems = [...state.productItems, ...productItems];
      }
     
    },
    SET_PAGINATION(state: any, data: any) {
    
      state.pagination = data
    },
    SET_LOADING(state: any, loading: any) {
      state.loading = loading;
    },
    INCREMENT_PAGE(state: any ) {
       state.currentPage++; 
     
    },
    CLEAR_INCREMENT_PAGE(state: any ) {
      state.currentPage =  1
    },
    CLEAR_SUGGESTION_PRODUCTS(state: any) {
      state.suggestionProducts = []
    },
    LAST_PAGE(state: any, lastPage : number) {
      state.lastPage = lastPage 
     }

  },
  actions: {
    /*getProductsList({ commit }: any, idRestaurant: number) {
      request.httpClient().get('productsAtPlace/' + idRestaurant).then((response) => {
        commit('UPDATE_PRODUCTS_LIST', response.data.categories)
      });
    },
    getProductsCACList({ commit }: any, idRestaurant: number) {
      request.httpClient().get('productsCAC/' + idRestaurant).then((response) => {
        commit('UPDATE_PRODUCTS_LIST', response.data.categories)
      });
    },*/


    getProductsList({ state, commit }: any, idRestaurant: number) {
     


      if (state.loading) return;
      if (state.loading || (state.lastPage !== null && state.currentPage > state.lastPage)) {
        return; // Stop if already on the last page
      }
      commit('SET_LOADING', true);

      request.httpClient().get(`productsAtPlacePaginate/${idRestaurant}/1?page=${state.currentPage}`).then((response) => {
        commit('SET_PRODUCTS', response.data.categories.data);
        commit('SET_PAGINATION', response.data.categories);
        commit('LAST_PAGE', response.data.categories.last_page);
        commit('INCREMENT_PAGE' );

      }).catch((error) => {
        console.error('Error fetching products:', error);
      }).finally(() => {
        commit('SET_LOADING', false);
      })

    },
     
    getProductsCACList({ state, commit }: any, idRestaurant: number) {
     
 
      if (state.loading) return;
      if (state.loading || (state.lastPage !== null && state.currentPage > state.lastPage)) {
        return; // Stop if already on the last page
      }
      commit('SET_LOADING', true);

      request.httpClient().get(`productsCACPaginate/${idRestaurant}/1?page=${state.currentPage}`).then((response) => {
        commit('SET_PRODUCTS', response.data.categories.data);
        commit('SET_PAGINATION', response.data.categories);
        commit('LAST_PAGE', response.data.categories.last_page);
        commit('INCREMENT_PAGE' );

      }).catch((error) => {
        console.error('Error fetching products:', error);
      }).finally(() => {
        commit('SET_LOADING', false);
      })
    },
 
    
   
    async fetchSuggestions({ state, commit }: any, ids: number[]) {
      commit('CLEAR_SUGGESTION_PRODUCTS')    
      // Find products that are already in the state
      const suggestionProducts = state.suggestedProducts.filter((product:any) => ids.includes(product.id));
      
      // Find IDs that are missing from the state
      const existingIds = suggestionProducts.map((product:any) => product.id);
      const missingIds = ids.filter(id => !existingIds.includes(id));

      // If some IDs are missing, fetch only those
      if (missingIds.length > 0) {
        request.httpClient().post(`productsSuggestions`, {
          product_ids: missingIds
        }).then((response) => {
          // Append new products to state
          commit('UPDATE_SUGGESTED_PRODUCTS', response.data.products	);
          
          commit('UPDATE_EXISTING_SUGGESTION_PRODUCTS', [...suggestionProducts, ...response.data.products]	);
          
        }).catch((error) => {
          console.error('Error fetching products:', error);
          commit('UPDATE_EXISTING_SUGGESTION_PRODUCTS', suggestionProducts);
         }).finally(() => {
          //
        })
      } else {
        // If all IDs exist, return only the existing ones
        commit('UPDATE_EXISTING_SUGGESTION_PRODUCTS', suggestionProducts);
      }
     
      
    },

    clearProductList({ commit }: any) {
      commit('UPDATE_PRODUCTS_LIST', [])
    },
    clearCurrentPage({ commit }: any) {
      commit('CLEAR_INCREMENT_PAGE')
    },
    clearSuggestionProducts({ commit }: any) {
      commit('CLEAR_SUGGESTION_PRODUCTS')
    }


  },
  getters: {
    
    suggestionProductsList: (state: any) => state.suggestionProducts,
    suggestedProductsList: (state: any) => state.suggestedProducts,
    productList: (state: any) => state.productItems,
    Loading: (state: any) => state.loading,
    LastPage: (state: any) => state.lastPage,
    CurrentPage: (state: any) => state.currentPage
    
  }

}
