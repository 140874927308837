<template>
  <Transition name="modal">
    <div v-if="show">
      <div class="mfp-bg my-mfp-zoom-in mfp-ready" @click="close"></div>
      <div class="
                  mfp-wrap mfp-close-btn-in mfp-auto-cursor
                  my-mfp-zoom-in
                  mfp-ready
                " tabindex="-1" style="overflow: hidden auto">
        <div class="mfp-container mfp-inline-holder">
          <div class="mfp-container mfp-inline-holder">
            <div class="mfp-content">
              <div id="modal-dialog" class="modal-dialog-nonbackground">
                <div class="" v-if="workTime">
                  <div class="mt-2 mb-4 d-block title-select">
                    <div class="opening-hours">
                      <table class="opening-hours-table">
                        <tbody>
                          <tr>
                            <td> {{ $t("order.delivery") }}</td>
                            <td>{{ openDeliveryMorning }} - {{ closeDeliveryMorning }}</td>
                            <td>{{ openDeliveryAfternoon }} - {{ closeDeliveryAfternoon }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("order.takeaway") }}</td>
                            <td>{{ openCACMorning }} - {{ closeCACMorning }}</td>
                            <td>{{ openCACAfternoon }} - {{ closeCACAfternoon }}</td>
                          </tr>
                          <tr>
                            <td> {{ $t("order.eatin") }}</td>
                            <td>{{ openMorning }} - {{ closeMorning }}</td>
                            <td>{{ openAfternoon }} - {{ closeAfternoon }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <h3 class="title-select d-block">
                    {{ $t("order.selectType") }}
                  </h3>
                </div>
                <div class="content" id="sign-in-dialog">
                  <div class="row">
                    <div class="select-container-type">
                      <div class="options" v-if="canEatIn && !isDisabledAtPlace && enabledQRCodeAtPlace">
                        <div class="select-container" @click="setOrderType('eatin')">
                          <div class="col-12">
                            <img src="@/assets/img/svg_icons/fast-food.svg" width="100" height="100" />
                          </div>
                          <div class="col-12 select-type text-center">
                            {{ $t("order.eatinAction") }}

                          </div>
                        </div>
                      </div>
                      <div class="options" v-if="canTakeOut && enabledQRTakeOut">
                        <div class="select-container" @click="setOrderType('takeaway')">
                          <div class="col-12">
                            <img src="@/assets/img/svg_icons/take-awayv3.svg" width="100" height="100" />
                          </div>
                          <div class="col-12 select-type text-center">
                            {{ $t("order.takeawayAction") }}
                          </div>
                        </div>
                      </div>
                      <div class="options" v-if="(canDelivery && !idTable && enabledQRCodeDelivery)">
                        <div class="select-container" @click="setOrderType('delivery')">
                          <div class="col-12">
                            <img src="@/assets/img/moto.png" width="100" height="100" />
                          </div>
                          <div class="col-12 select-type text-center">
                            {{ $t("order.deliveryAction") }}
                          </div>
                        </div>
                      </div>
                      <!--   -->
                      <div class="options" v-if="idTable && canEatIn && !isDisabledAtPlace && enabledQRCodeAtPlace">
                        <div class="select-container" @click="setOrderType('ordertable')">
                          <div class="col-12">
                            <img src="@/assets/img/ordertable.png" width="100" height="100" />
                          </div>
                          <div class="col-12 select-type text-center">
                            {{ $t("order.ordertableAction") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="regexLink(cartLink)" class="row mt-3">
                    <div class="options">
                      <div class="select-container" @click="navigateTo(cartLink)">
                        <div class="col-12">
                          <img src="@/assets/img/cart.png" width="100" height="100" />
                        </div>
                        <div class="col-12 select-type">
                          {{ $t("order.menu") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapActions } from "vuex";
import router from "../router";
@Options({
  props: {
    show: Boolean,
    laoading: Boolean,
  },

  data() {
    return {
      errors: [],
    };
  },
  // mounted() {

  // },
  methods: {
    regexLink(link: string) {
      const regex = /^(ftp|http|https):\/\/[^ "]+$/;
      if (regex.test(link)) {
        return true
      }
      return false
    },
    close() {
      this.$emit("close");
    },
    formatTime(time: any) {
      if (!time) {
        return "Closed";
      }
      const [hour, minute] = time.split(':');
      return `${hour}:${minute}`;
    },
    ...mapActions(["removeCartItem"]),
    setOrderType(type: string) {

      let toBeDeleted = 0;
      if (type !== this.orderType) {
        // we need to check cart befor going next steap eatin isAtPlace: 1  || takeaway isDelivery: 1

        this.ordredProducts.forEach((element: any) => {
          if ((type === "eatin" && !element.isAtPlace)) toBeDeleted++;
          if (type === "takeaway" && !element.isDelivery) toBeDeleted++;
        });
      }
      if (toBeDeleted > 0) {
        if (
          confirm(
            `${toBeDeleted} Produit(s) dons votre panier seront supprimés.`
          )
        ) {
          // we need to remove that specifique product
          for (let i = this.ordredProducts.length - 1; i >= 0; i--) {
            if ((type === "eatin" && !this.ordredProducts[i].isAtPlace)) this.removeCartItem(i);
            if (type === "takeaway" && !this.ordredProducts[i].isDelivery) this.removeCartItem(i);
          }
          this.ordredProducts.forEach((element: any) => {
            if ((type === "eatin" && !element.isAtPlace)) toBeDeleted++;
            if (type === "takeaway" && !element.isDelivery) toBeDeleted++;
          });

          this.$store.dispatch("clearProductList");
          this.$store.dispatch("updateOrderType", type);
          this.$store.dispatch('clearCurrentPage');
          if (type === "eatin") {
            
            this.$store.dispatch(
              "getProductsList",
              this.slugRestaurant
            );
          } else {
             this.$store.dispatch(
              "getProductsCACList",
              this.slugRestaurant
            );
          }
        }
      } else {
        this.$store.dispatch("clearProductList");
        this.$store.dispatch("updateOrderType", type);
        this.$store.dispatch('clearCurrentPage');
        if (type === "eatin") {
          this.$store.dispatch(
            "getProductsList",
            this.slugRestaurant
          );
        } else {
          this.$store.dispatch(
            "getProductsCACList",
            this.slugRestaurant
          );
        }
      }
      const data = { idRestaurant: router.currentRoute.value.params.idRestaurant, idTable: router.currentRoute.value.params.idTable }
      if (type == 'ordertable') {
        this.$store.dispatch("updateOrderTable", 'ordertable');
        this.$store.dispatch("updateOrderType", 'eatin');
        router.push({ name: "OrderTableView", params: { idRestaurant: data.idRestaurant, idTable: data.idTable } });
      }
      if (router.currentRoute.value.name == "OrderTableView" && type !== 'ordertable') {

        router.push({ name: "homeview", params: { idRestaurant: data.idRestaurant, idTable: data.idTable } });
      }


      this.$emit("close");
    },
    navigateTo(link: string) {
      window.open(link, '_blank');
    }
  },
  components: {},
  computed: {
    ...mapGetters(["customerInfo"]),
    ...mapGetters(["orderType"]),
    ...mapGetters(["orderTable"]),
    ...mapGetters(["ordredProducts"]),
    ...mapGetters(["canEatIn"]),
    ...mapGetters(["canTakeOut"]),
    ...mapGetters(["canDelivery"]),
    ...mapGetters(["isDisabledAtPlace"]),
    ...mapGetters(["slugRestaurant"]),
    ...mapGetters(["cartLink"]),
    ...mapGetters(["idTable"]),
    ...mapGetters(["workTime"]),
    ...mapGetters(["enabledQRCodeAtPlace"]),
    ...mapGetters(["enabledQRCodeDelivery"]),
    ...mapGetters(["enabledQRTakeOut"]),
    openDeliveryMorning() {
      return this.formatTime(this.workTime.openDeliveryMorning);
    },
    closeDeliveryMorning() {
      return this.formatTime(this.workTime.closeDeliveryMorning);
    },
    openDeliveryAfternoon() {
      return this.formatTime(this.workTime.openDeliveryAfternoon);
    },
    closeDeliveryAfternoon() {
      return this.formatTime(this.workTime.closeDeliveryAfternoon);
    },
    openCACMorning() {
      return this.formatTime(this.workTime.openCACMorning);
    },
    closeCACMorning() {
      return this.formatTime(this.workTime.closeCACMorning);
    },
    openCACAfternoon() {
      return this.formatTime(this.workTime.openCACAfternoon);
    },
    closeCACAfternoon() {
      return this.formatTime(this.workTime.closeCACAfternoon);
    },
    openMorning() {
      return this.formatTime(this.workTime.openMorning);
    },
    closeMorning() {
      return this.formatTime(this.workTime.closeMorning);
    },
    openAfternoon() {
      return this.formatTime(this.workTime.openAfternoon);
    },
    closeAfternoon() {
      return this.formatTime(this.workTime.closeAfternoon);
    },
  },
})
export default class CommandeTypeModal extends Vue { }
</script>

<style>
.opening-hours {
  background: #555;
  border-radius: 7px;
  padding: 7px;
}

.opening-hours-table {
  border-collapse: collapse;
  width: 100%;
}

.opening-hours-table th,
.opening-hours-table td {
  border: none;
  text-align: center;
  padding: 0px;
}

.opening-hours-table tr:nth-child(even) {
  //background-color: #f2f2f2;
}

.modal-dialog-nonbackground {
  background: none !important;
}

#modal-dialog {
  position: relative !important;
}

form {
  padding: 0px 3px 14px 3px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

#sign-in-dialog {
  background: none !important;
  margin: 16px auto !important;
}

#modal-dialog .content {
  padding: 1px 20px 11px 20px !important;
}

.small-dialog-header {
  display: flex !important;
  justify-content: center;
}

:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 32px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.select-container {
  width: 110px !important;
  background: #fff !important;
  border-radius: 22px !important;
  padding: 17px 5px !important;
}

.options {
  flex: 1;
  justify-content: center;
  display: flex;
}

.select-type {
  justify-content: center;
  display: flex;
}

.title-select {
  color: #fff;
  text-align: center;
}

.select-container-type {
  display: flex;
  padding: 0px;
}

.top-bar-hider {
  background: none !important;
  color: #fff !important;
}
</style>